// Global styles can go here if needed

// Define styles for the murc component
#murc {
  .details {
    .description {
      // Common styles for images
      img {
        margin: 1rem auto; // Default margin for images
        max-width: 100%; // Ensure images don't exceed their container width
        height: auto; // Maintain aspect ratio when scaling
      }
    }
  }
}

// Media queries for different screen sizes
@media screen and (max-width: 767px) {
  #murc {
    .details {
      .description {
        img {
          margin: 0.5rem auto; // Adjusted margin for smaller screens
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  #murc {
    .details {
      .description {
        img {
          margin: 1rem auto; // Default margin for larger screens
        }
      }
    }
  }
}